import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AnimatedButton } from "../shared/button";
import Logo from "../../images/logo.svg";
import {
  FaDiscord,
  FaGithub,
  FaTelegram,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const socialLinks =
    "text-primary-color hover:text-purple-400 transition-colors";

  const navLinkStyles = ({ isActive }) =>
    ` transition-all duration-300 cursor-pointer ${
      isActive ? "text-[#D0F] font-bold" : "text-[#ECF1F0] hover:text-[#D0F]"
    }`;

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 30;
      setHasScrolled(window.scrollY > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      className={`fixed top-0 left-0 container mx-auto right-0 z-[60] flex justify-between items-center  ${
        hasScrolled ? "backdrop-blur-sm shadow-lg bg-transparent" : ""
      } px-4 py-4  transition-all duration-300`}
    >
      <a href="https://whalesnipr.com">
        <div className="flex items-center font-raleway gap-3">
          <span className="text-white md:text-2xl text-xl font-bold">
            Whale<span className="text-[#75FB69]">Sniper</span>
          </span>
          <img width={41} height={41} src={Logo} alt="Whalesnipr Logo" />
        </div>
      </a>
      <div className="lg:flex hidden font-raleway font-semibold space-x-12">
        <NavLink to="/" className={navLinkStyles}>
          Home
        </NavLink>
        <NavLink to="/tools" className={navLinkStyles}>
          Tools
        </NavLink>
        <a
          className="text-[#ECF1F0] hover:text-[#D0F] transition-all duration-300 cursor-pointer"
          href="https://www.youtube.com/@Whalesnipr"
          target="_blank"
          rel="noreferrer"
        >
          Youtube
        </a>
      </div>
      <a
        className="lg:flex hidden"
        href="https://discord.gg/8W8TdfKXMT"
        target="_blank"
        rel="noreferrer"
      >
        <AnimatedButton buttonText={"Join Now"} />
      </a>

      {/* Hamburger Menu */}
      <div className="lg:hidden block hamburger-menu">
        <button
          className={`hamburger-button ${isMenuOpen ? "open" : ""}`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <div></div>
          <div></div>
          <div></div>
        </button>
      </div>

      {/* Mobile Menu */}
      <div className={`mobile-menu  ${isMenuOpen ? "open" : ""}`}>
        <div className="grid place-content-center space-y-4 py-6">
          <NavLink
            style={{ textDecoration: "none" }}
            onClick={() => setIsMenuOpen(false)}
            to="/"
            className={navLinkStyles}
          >
            <button className="text-button">Home</button>
          </NavLink>
          <NavLink
            style={{ textDecoration: "none" }}
            onClick={() => setIsMenuOpen(false)}
            to="/tools"
            className={navLinkStyles}
          >
            <button className="text-button">Tools</button>
          </NavLink>
          <a
            href="https://www.youtube.com/@Whalesnipr"
            target="_blank"
            rel="noreferrer"
            onClick={() => setIsMenuOpen(false)}
          >
            <button className="text-button">Youtube</button>
          </a>
          <a
            href="https://discord.gg/8W8TdfKXMT"
            target="_blank"
            rel="noreferrer"
            onClick={() => setIsMenuOpen(false)}
          >
            <AnimatedButton buttonText={"Join"} />
          </a>
        </div>

        <div className="mobile-social-links pb-6">
          <a
            href="https://x.com/whalesnipr"
            target="_blank"
            rel="noreferrer"
            className={socialLinks}
          >
            <FaXTwitter size={24} />
          </a>
          <a
            className={socialLinks}
            href="https://discord.gg/8W8TdfKXMT"
            target="_blank"
            rel="noreferrer"
          >
            <FaDiscord size={24} />
          </a>
          <a
            className={socialLinks}
            href="https://t.me/whalesniprs"
            target="_blank"
            rel="noreferrer"
          >
            <FaTelegram size={24} />
          </a>
          <a
            className={socialLinks}
            href="https://www.youtube.com/@Whalesnipr"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube size={24} />
          </a>
          <a
            className={socialLinks}
            href="https://github.com/Whalesnipr-Foundation"
            target="_blank"
            rel="noreferrer"
          >
            <FaGithub size={24} />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

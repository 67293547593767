import React, { useEffect, useState } from "react";

import Looper3 from "../../images/Looper-3.png";
import Giannis from "../../images/Giannis.png";
import R3vz from "../../images/r3vz.webp";
import Andriu from "../../images/andriu.webp";
import Temple from "../../images/temple.jpg";
import { TestimonialBlob, TestimonialStar } from "../shared/svg";
import { Transition } from "../../animation/transition";

const testimonials = [
  {
    id: 1,
    name: "Giannis",
    location: "",
    text: "Fantastic experience! The bot is smooth, intuitive, and user-friendly. Highly recommend it for anyone needing a reliable automated tool. Great work!",
    avatar: Giannis,
  },
  {
    id: 2,
    name: "r3vz",
    location: "",
    text: "Whalesnipr Nobles is the real deal! Mr. Pathfindr and the crew provide top-tier insights, well-researched calls, and real opportunities—no hype, just results. A game-changer for serious crypto investors!",
    avatar: R3vz,
  },
  {
    id: 3,
    name: "Andriu",
    location: "",
    text: "The Noble Membership is one of the best communities! Mr. Pathfindr’s insights and explanations are a game changer for understanding the market. Totally worth it!",
    avatar: Andriu,
  },
  {
    id: 4,
    name: "Temple",
    location: "",
    text: "Whalesnipr has been an incredible learning experience! Mr. Pathfindr’s tutorials are straight to the point, and the community is rich with valuable insights. Huge thanks to Mr. Pathfindr and the Noble Crew!",
    avatar: Temple,
  },
];

const Testimonial = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let timer;
    if (!isHovered) {
      timer = setInterval(() => {
        setActiveIndex((prev) => (prev + 1) % testimonials.length);
      }, 5000);
    }
    return () => clearInterval(timer);
  }, [isHovered]);

  return (
    <section className="flex xl:justify-between justify-center xl:flex-row flex-col items-center xl:px-[9.5rem] relative p-0 md:mt-52 mt-24 w-full">
      <img
        src={Looper3}
        alt="testimonial background"
        className="-z-30 object-cover md:-bottom-[18rem] md:left-[12rem] md:w-[80%] w-full absolute"
      />

      <div className="md:w-[38rem] w-full relative">
        <h3 className="text-[#F2F2F2] xl:pl-3 pb-3 md:text-center xl:text-start font-raleway text-lg font-bold uppercase leading-normal">
          Testimonial
        </h3>
        <Transition>
          <h3 className="text-white font-press-start text-center xl:text-start leading-10 xl:text-[2.375rem] text-[1.5rem] font-normal normal-case">
            What people say about us.
          </h3>
        </Transition>
        <TestimonialStar className="absolute xl:block hidden top-48" />
        <TestimonialStar className="absolute xl:block hidden top-64 left-20" />
      </div>

      <TestimonialBlob className="absolute xl:top-0 md:top-32 top-24 xl:right-20 right-0 xl:w-[50%] w-full" />

      <div className="relative h-[400px] w-full flex-grow flex items-center xl:justify-end justify-center xl:mr-12 mr-0">
        {testimonials.map((testimonial, index) => {
          const offset =
            (index - activeIndex + testimonials.length) % testimonials.length;

          return (
            <div
              key={testimonial.id}
              className="absolute rounded-[0.625rem] font-raleway bg-[#160E33] shadow-[14px_17px_14px_-4px_rgba(165,165,165,0.06)] transition-all duration-500 ease-in-out md:w-[31.49194rem] w-[90%]"
              style={{
                transform: `translate(${offset * 24}px, ${offset * 24}px)`,
                zIndex: testimonials.length - offset,
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div className="md:h-[15.30856rem] h-[10.04463rem] md:p-10 p-8 flex flex-col">
                <div className="items-center gap-4 mb-6 absolute -top-8 -left-8">
                  <img
                    src={testimonial.avatar}
                    alt=""
                    className="w-[4.24894rem] h-[4.24894rem] rounded-full"
                  />
                </div>

                <div className="flex-1 overflow-y-auto scrollbar-hide">
                  <p className="text-white text-[0.656rem] md:text-[0.99975rem]  font-[500] w-full md:w-[25.11856rem] leading-relaxed">
                    "{testimonial.text}"
                  </p>
                </div>

                <div className="mt-auto">
                  <h4 className="text-white text-[0.738rem] pt-2 md:text-[1.12rem] font-[600] mb-1">
                    {testimonial.name}
                  </h4>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Testimonial;
